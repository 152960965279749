<template>
  <div class="footer">
    Copyright © 2024-2025 宠爱百货 版权所有&nbsp;&nbsp; &nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">新ICP备2024005419号</a>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.footer{
  padding: 20px;
  display: flex;
  align-items: center;
}
</style>